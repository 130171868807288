/**
 * @module router/configs
 * @description 在对应的路由配置项数组中添加菜单数据库对应的资源 ID 即可, 后续将所有配置迁移至后端, 在表里增加字段
 *
 * @see sys_permission (all id form the db)
 */
/** ROUTER CONFIGS */
module.exports = {
  /**
   * @type {Array<number>}
   * @description 此数组中的 ID 对应的路由, 节点将不会出现在侧边栏菜单
   * TODO: 产品管理菜单 520，临时隐藏
   */
  hidden: [
    11, 52, 73, 85, 135, 170, 183, 186, 188, 191, 193, 196, 199, 202, 204, 207,
    173, 176, 178, 182, 217, 233, 274, 295, 282, 338, 306, 365, 364, 363, 232,
    273, 281, 294, 307, 336, 215, 261, 260, 267, 268, 315, 316, 249, 250, 302,
    306, 307, 344, 345, 352, 352, 477, 478, 472, 473, 467, 468, 462, 463, 448,
    449, 326, 331, 451, 1419, 501, 615, 616, 392, 422, 561, 393, 51, 75, 84,
    136, 10, 491, 492, 494, 496, 490, 1140, 1252, 1253, 1168, 1169, 1199, 1200,
    1261, 1262, 1151, 1150, 1160, 1243, 1244, 1270, 1271, 1280, 1308, 1295,
    1386, 1387, 525, 526, 533, 534, 538, 539, 1428, 1429, 1439, 1440, 1481,
    1482, 1488, 1489, 1500, 1501, 1503, 1504, 1514, 1490, 1511, 520, 1523, 1524,
    1522, 1539, 1540, 1548, 1549, 1584, 1586, 1588, 1589, 1592, 1598, 1657,
    1658, 1661, 1666, 1565, 1568, 1680, 1600, 1761, 1762, 1772, 1782, 1790,
    1756, 1757, 1619, 1159, 1857, 1858, 1837, 1838, 1289, 1862, 1981, 147, 2003,
    2006, 2005, 2367, 2404, 2405, 2428, 2429, 2438, 2448, 2449, 2459, 2487,
    2534, 2537, 2557, 2563, 2542, 2581, 2582, 2604, 2618, 2595, 2596, 2656,
    2657, 2661, 2669, 2675
  ],
  /**
   * @type {Array<number>}
   * @description 此数组中的 ID 对应的路由, 即使子路由数量为一个也会显示，否则会将那个子路由当做根路由显示在侧边栏
   */
  alwaysShow: [210, 2010],
  /**
   * @type {Array<number>}
   * @description 此数组中的 ID 对应的路由, 不会出现在 tags-view 栏中
   */
  noTag: [],
  /**
   * @type {Array<number>}
   * @description 此数组中的 ID 对应的路由, 不会被 <keep-alive> 缓存
   */
  noCache: [
    2, // 首页不缓存
    40, // 权限管理缓存 Bug 暂无找到原因不缓存
    /**
     * 列表刷新问题，列表已经 exclude, 还有一些 非 list 目录的 列表
     */
    22, //  部门管理
    29, // 日志管理
    34, // 职位管理
    47, // 日志管理
    3, // 流程管理
    432, // 流程管理/流程分类
    513, // 流程管理/待办配置
    163, // 流程管理/流程设计
    451 // 流程管理/流程设计/图
  ],
  /**
   * @type {Array<number>}
   * @description 此数组中的 ID 对应的路由, 不会在 breadcrumb 面包屑中显示, 另外 path 为 'list'，'detail'，'create' , 'processDesign' 等， 不会在 breadcrumb 面包屑中显示
   */
  noBreadcrumb: [],
  /**
   * @type {Array<number>}
   * @description 此数组中的 ID 对应的路由将会固定在 tags-view 栏中
   */
  affix: [],
  /**
   * @type {Array<number>}
   * @description 此数组中的 ID 对应的路由菜单将会禁止点击
   */
  disabled: [
    /**
     * 产品管理
     */
    521, //  样品申请
    522, //  项目卡片
    523, // 产品开发
    1175 // 产品定义定价管理
  ],
  /**
   * @type {Array<number>}
   * @description 此数组中的 ID 对应的路由, 页面的 class="app-container" 将会固定为 "主区域视窗" 的高度不会根据内容自动延伸
   * "主区域视窗" 意为 100vh - 顶部栏(if show) -  标签栏(if show) - 标题栏(if has, include slots that like filter-form) - 上下的外边距
   *  xxx/list 已经全局固定
   */
  fixedHeight: [
    /**
     * 系统管理
     */
    22, // 部门管理
    34, // 职位管理
    40 // 权限管理
    // /**
    //  * 供应链
    //  */
    // 425 // 订单管理》扫描验货
  ]
}
