var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('u-table',_vm._g(_vm._b({directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],ref:"table",class:['vtable', _vm.tableKey],attrs:{"border":"","fixed-columns-roll":"","tree-config":{
      ..._vm.treeConfig,
      load: _vm._loadChildMethod
    },"big-data-checkbox":"","beautify-table":"","use-virtual":"","row-id":"id","row-key":"id","header-cell-class-name":_vm.getHeaderClassName,"cell-class-name":_vm.getCellClassName,"row-style":_vm.getRowStyle},on:{"header-dragend":_vm.onHeaderDragend,"select":_vm.onSelect,"select-all":_vm.onSelect,"cell-mouse-enter":_vm.handleRowMouseEnter,"cell-mouse-leave":_vm.handleRowMouseLeave,"sort-change":_vm.onSortChange,"row-contextmenu":_vm.onRowContextMenu}},'u-table',_vm.proxyAttrs,false),_vm.proxyListener),[_vm._l((_vm.tableHeader),function(headerConfig){return _c('u-table-column',_vm._b({key:headerConfig.type ?? headerConfig.prop,attrs:{"render-header":(h, e) => _vm.renderHeader(h, e, headerConfig),"filters":headerConfig.filters ? headerConfig.filters : undefined,"reserve-selection":_vm.reserveSelection},scopedSlots:_vm._u([(_vm.isNotIndexOrCheckbox(headerConfig))?{key:"default",fn:function(slotParams){return [(
            headerConfig.autoCopy &&
            _vm.hoveredRow === slotParams.row &&
            slotParams.row[headerConfig.prop]
          )?_c('svg-icon',{staticClass:"copySvg",staticStyle:{"cursor":"pointer"},attrs:{"icon-class":"i-copy-hollow"},on:{"click":function($event){$event.stopPropagation();return _vm.goHandleCopy(slotParams.row[headerConfig.prop])}}}):_vm._e(),(headerConfig.isComp)?_vm._t(headerConfig.prop,null,null,slotParams):(
            (typeof slotParams.row[headerConfig.prop] === 'string' &&
              slotParams.row[headerConfig.prop] !== '' &&
              slotParams.row[headerConfig.prop].trim()) ||
            typeof slotParams.row[headerConfig.prop] === 'number'
          )?_c('span',{class:[headerConfig.prop === 'index' ? 'customer-index' : '']},[_vm._v(" "+_vm._s(typeof headerConfig.formatter === 'function' ? headerConfig.formatter(slotParams.row) : slotParams.row[headerConfig.prop])+" ")]):_c('span',{staticClass:"table-na-placeholder"},[_vm._v(" "+_vm._s(typeof headerConfig.formatter === 'function' ? headerConfig.formatter(slotParams.row) : '⏤')+" ")])]}}:null],null,true)},'u-table-column',_vm.handleOtherHeaderConfig(headerConfig),false))}),_vm._t("operation")],2),(_vm.menuVisible)?_c('div',{ref:"contextMenu",staticClass:"task-context-menu",style:({ top: _vm.menuPosition.y + 'px', left: _vm.menuPosition.x + 'px' })},[_c('ul',_vm._l((_vm.menuList),function(item,index){return _c('li',{key:index,on:{"click":item.options}},[_c('a',[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }